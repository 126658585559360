import * as React from "react"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import { StaticImage } from "gatsby-plugin-image"
import ConstructionIcon from '@mui/icons-material/Construction';
import Box from '@mui/material/Box';
import  './index.css';



// styles
const pageStyles = {
  color: "#232129",
  //paddingLeft: 2,
  //paddingRight: 2,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  backgroundColor: "white",
  width: "100%",
  height: "100%"
}
const headingStyles = {
  //marginBottom: 64,
  //maxWidth: 320,
  textAlign: "center"
}
const headingAccentStyles = {
  //color: "#663399",
  color: "black",
  paddingLeft: "25vw",
  paddingRight: "25vw",
  textAlign: "center",
  //fontFamily: 'Brush Script MT, cursive',
  fontFamily: 'Ink Free',

  fontSize: "150%"
  //marginBottom: "5px"
}







// markup
const IndexPage = () => {
  return (
        <div className="app" >
          <head>
          <title>Home Page</title>
        <link href="index.css" rel="stylesheet" />

      
      </head>

      
        <StaticImage src="../images/underConstructionMenu.jpg" 
        alt="Smokey heaven"
        placeholder="blurred"
        quality={100}
        layout="fullWidth"
        />
        <Box sx={{ /*p: 2, border: '1px dashed grey',*/ marginTop: "6px", textAlign:"center", color: "black",   bottom: "10px",
 }}>
        <div className="animate-flicker">
        <ConstructionIcon fontSize="large"/>
        </div>
        <p style={headingAccentStyles}><b>Sivusto on työnalla.</b></p>
        </Box>
      {/*</main>*/}
      </div>
  )
}

export default IndexPage



/*  <p style={paragraphStyles}>
        Edit <code style={codeStyles}>src/pages/index.js</code> to see this page
        update in real-time.{" "}
        <span role="img" aria-label="Sunglasses smiley emoji">
          😎
        </span>
      </p>
      <ul style={listStyles}>
        <li style={docLinkStyle}>
          <a
            style={linkStyle}
            href={`${docLink.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
          >
            {docLink.text}
          </a>
        </li>
        {links.map(link => (
          <li key={link.url} style={{ ...listItemStyles, color: link.color }}>
            <span>
              <a
                style={linkStyle}
                href={`${link.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
              >
                {link.text}
              </a>
              {link.badge && (
                <span style={badgeStyle} aria-label="New Badge">
                  NEW!
                </span>
              )}
              <p style={descriptionStyle}>{link.description}</p>
            </span>
          </li>
        ))}
      </ul>
      <img
        alt="Gatsby G Logo"
        src="data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2a10 10 0 110 20 10 10 0 010-20zm0 2c-3.73 0-6.86 2.55-7.75 6L14 19.75c3.45-.89 6-4.02 6-7.75h-5.25v1.5h3.45a6.37 6.37 0 01-3.89 4.44L6.06 9.69C7 7.31 9.3 5.63 12 5.63c2.13 0 4 1.04 5.18 2.65l1.23-1.06A7.959 7.959 0 0012 4zm-8 8a8 8 0 008 8c.04 0 .09 0-8-8z' fill='%23639'/%3E%3C/svg%3E"
      />
    </main>*/